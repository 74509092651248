#printHeader,
#printFooter {
	display: none;
}

body {
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #313131;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 300;
}

hr {
	border: 0;
	border-bottom: 1px solid #ccc;
	margin: 2rem 0;
}

h1 {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	color: #213a7f;
	font-size: 1.8rem;
	line-height: 1.3;
	font-weight: 300;
	text-transform: uppercase;
}

h2 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #213a7f;
	font-size: 1.6rem;
	line-height: 1.3;
	font-weight: 600;
}

h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #213a7f;
	font-size: 1.4rem;
	line-height: 1.3;
	font-weight: 600;
}

h4 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #213a7f;
	font-size: 1.2rem;
	line-height: 1.3;
	font-weight: 600;
}

h5 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #213a7f;
	font-size: 1rem;
	line-height: 1.4;
	font-weight: 700;
}

h6 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	color: #213a7f;
	font-size: 0.9rem;
	line-height: 1.4;
	font-weight: 700;
	text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	-webkit-transition: border 200ms ease;
	transition: border 200ms ease;
	border-bottom: 1px solid #797676;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
}

p {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1.125rem;
	line-height: 1.3;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #313131;
	text-decoration: none;
}

a:hover {
	color: #797676;
	text-decoration: none;
}

.inside-content-column a {
	border-bottom: 1px solid #797676;
}

.inside-content-column a:hover {
	border-bottom-color: transparent;
}

ul {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	padding-left: 20px;
	font-size: 1em;
}

ol {
	margin-top: 0.5rem;
	margin-bottom: 1rem;
	font-size: 1em;
}

li {
	margin-bottom: 0.25rem;
}

img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

label {
	display: block;
	margin-bottom: 0.25em;
	line-height: 1.25em;
	font-weight: 700;
}

strong {
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1.25rem;
	margin-bottom: 1.25rem;
	padding: 1rem 1.5rem;
	border-left: 5px solid #213a7f;
	font-size: 1.25rem;
	line-height: 1.75rem;
	background-color: rgba(170, 224, 250, 0.36);
}

blockquote p {
	font-size: 1.25rem;
	line-height: 1.3;
}

blockquote > *:first-of-type {
	margin-top: 0;
}

blockquote > *:last-of-type {
	margin-bottom: 0;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.primary,
.secondary,
.tertiary,
a.primary,
a.secondary,
a.tertiary,
.button,
.inside-content-column .primary,
.inside-content-column .secondary,
.inside-content-column .tertiary,
.inside-content-column a.primary,
.inside-content-column a.secondary,
.inside-content-column a.tertiary,
.inside-content-column .button {
	display: inline-block;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	padding: 7px 1.15rem 8px;
	background-color: #213a7f;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.05rem;
	text-decoration: none;
	text-transform: uppercase;
	border: 0;
	cursor: pointer;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.primary:hover,
.secondary:hover,
.tertiary:hover,
a.primary:hover,
a.secondary:hover,
a.tertiary:hover,
.button:hover,
.inside-content-column .primary:hover,
.inside-content-column .secondary:hover,
.inside-content-column .tertiary:hover,
.inside-content-column a.primary:hover,
.inside-content-column a.secondary:hover,
.inside-content-column a.tertiary:hover,
.inside-content-column .button:hover {
	background-color: #dee2ec;
	color: #213a7f;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.secondary,
a.secondary,
.button.secondary,
.inside-content-column .secondary,
.inside-content-column a.secondary,
.inside-content-column .button.secondary {
	background-color: #dee2ec;
	color: #213a7f;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.secondary:hover,
a.secondary:hover,
.button.secondary:hover,
.inside-content-column .secondary:hover,
.inside-content-column a.secondary:hover,
.inside-content-column .button.secondary:hover {
	background-color: #213a7f;
	color: #fff;
}

.tertiary,
a.tertiary,
.button.tertiary,
.inside-content-column .tertiary,
.inside-content-column a.tertiary,
.inside-content-column .button.tertiary {
	min-width: 0px;
	padding: 0.25rem 0.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: #213a7f;
	background-color: #fff;
	color: #213a7f;
	font-size: 0.9rem;
}

.tertiary:hover,
a.tertiary:hover,
.button.tertiary:hover,
.inside-content-column .tertiary:hover,
.inside-content-column a.tertiary:hover,
.inside-content-column .button.tertiary:hover {
	background-color: #dee2ec;
}

.button.header-search-button {
	width: 2rem;
	height: 2rem;
	min-width: 0px;
	margin-top: 0em;
	margin-right: 0rem;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	background-color: #a09e9c;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/search_wht.svg");
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 1rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: #6b6b6b;
}

.button.intro-cbox-learn-more {
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	background-color: #213a7f;
}

.button.intro-cbox-learn-more:hover {
	background-color: #aae0fa;
	color: #213a7f;
}

.button.intro-cbox-learn-more.lower-cb-learn-more {
	background-color: #fff;
	color: #222221;
	font-weight: 700;
}

.button.intro-cbox-learn-more.lower-cb-learn-more:hover {
	background-color: #c9c6c4;
}

.container {
	max-width: 1900px;
	padding-right: 20px;
	padding-left: 20px;
}

.container.home-cb-container {
	max-width: 100%;
	padding: 2vw 4vw 6vw;
	background-color: #fff;
}

.container.home-cb-container.home-intro-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 2vw;
	padding-bottom: 3vw;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f6f6f6;
}

.container.home-cb-container.lower-cta-container {
	display: none;
	padding: 8vw 12vw;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5)));
	background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
}

.container.home-cb-container.featured-container {
	padding-top: 2vw;
	padding-bottom: 0vw;
	border-bottom-style: none;
}

.container.home-cb-container.about-our-company {
	border-top: 1px solid #213a7f;
	border-bottom: 1px solid #213a7f;
	padding: 3vw 4vw;
	position: relative;
}

.about-our-company-image {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	left: 0;
	opacity: 0.1;
	background-repeat: no-repeat;
	background-size: auto 90%;
	background-position: center;
}

.container.home-cb-container.about-our-company .cta-paragraph {
	text-align: center;
	color: #313131;
	word-break: break-word;
}

.container.home-cb-container.about-our-company p {
	max-width: none;
}

.container.footer-container {
	max-width: 1900px;
	padding-top: 2rem;
	padding-right: 10.5vw;
	padding-left: 10.5vw;
	border-top: 5px solid #213a7f;
	background-color: #fff;
	color: #000;
}

.container.footer-container.signoff-container {
	padding-right: 10.5vw;
	padding-left: 10.5vw;
	border-top-style: none;
	padding-top: 0;
}

.container.inside-container {
	min-height: 1px;
	max-width: 1500px;
	padding: 1vw 4vw 3vw;
	background-color: #fff;
}

.nav-section {
	height: auto;
	background-color: transparent;
}

.nav-container {
	width: 100%;
	height: auto;
	max-width: 100%;
	margin-top: 0px;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-transition: none 0ms ease;
	transition: none 0ms ease;
}

.nav-link {
	display: inline-block;
	margin-right: 1px;
	margin-left: 1px;
	padding: 0.5rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/tab-01.svg");
	background-position: 50% 125%;
	background-size: 50px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	color: #313131;
	line-height: 1.375rem;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-link:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-link.w--current {
	background-position: 50% 104%;
	color: #6b6b6b;
}

.nav-link.w--current:hover {
	color: #677179;
}

.nav-menu-wrap {
	left: auto;
	top: 21px;
	right: -1%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0px;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-left: 1px none rgba(0, 0, 0, 0.15);
	font-size: 1.1rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: right;
	margin-top: 0.8rem;
}

.nav-menu-wrap.gr {
	top: 38px;
}

.main-ul {
	padding: 0;
	margin: 0;
	list-style: none;
}

.logo-link {
	top: -34px;
	display: block;
	width: auto;
	max-width: none;
	margin-top: 0px;
	margin-right: 0rem;
	margin-bottom: -27px;
	padding-top: 0px;
	padding-left: 0px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition-property: none;
	transition-property: none;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
	cursor: pointer;
}

.logo-link:hover {
	color: #fff;
}

.footer-section {
	position: relative;
	padding-top: 0rem;
	background-color: #fff;
	font-size: 0.9375rem;
	line-height: 1.5em;
}

#GRIPFooterLogoText {
	padding-left: 26px !important;
}

#GRIPFooterLogoImg {
	background-size: 150px 40px !important;
	width: 150px !important;
	height: 40px !important;
	margin-top: -17px !important;
	margin-left: -12px !important;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-between;
}

.row.home-cbox-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 2rem;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.column.home-cbox-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.column.footer-signoff-right-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.logo-image {
	width: auto;
	max-width: 123px;
}

.footer-signoff-section {
	margin-top: 0.5rem;
	padding-top: 0rem;
	padding-bottom: 2rem;
	background-color: #fff;
	color: #8d999f;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 300;
}

.copyright-text {
	display: inline-block;
	margin-right: 0rem;
	margin-left: 0rem;
	color: #313131;
}

.copyright-text h1 {
	display: inline-block;
	margin-right: 0rem;
	margin-left: 0rem;
	color: #313131;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 300;
	margin: 0;
	padding: 0;
	text-transform: none;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 1rem;
	padding-left: 0px;
	color: #6b6b6b;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	margin-right: 1em;
	margin-left: 1em;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/globalreach_flat_gray.png");
	background-size: 130px 28px;
	background-repeat: no-repeat;
	font-family: 'Roboto Condensed', Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #999;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #999;
}

.footer-column-wrap {
	display: block;
	margin-bottom: 1rem;
	padding-right: 1rem;
	padding-bottom: 1rem;
	clear: both;
}

.footer-column-wrap:last-of-type {
	padding-right: 0;
}

.footer-column-wrap ul {
	font-size: 1rem;
}

.footer-column-wrap.centered-footer-column {
	text-align: center;
}

.footer-column-title {
	margin-top: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	color: #213a7f;
	font-size: 1.375em;
	line-height: 1.25em;
	font-style: normal;
	font-weight: 600;
	text-transform: uppercase;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	padding-top: 10px;
	padding-right: 4vw;
	padding-bottom: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	opacity: 1;
}

.header-contents-wrap {
	position: relative;
	z-index: 4500;
	display: block;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0px;
	color: #313131;
	font-size: 0.8rem;
	line-height: 1rem;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	background-color: transparent;
}

.form-wrap {
	margin-bottom: 0px;
	font-family: 'Roboto Condensed', Nunito;
	font-size: 1rem;
	font-weight: 300;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	font-weight: 300;
}

.form-field.header-search-field {
	width: 170px;
	height: 2rem;
	margin-bottom: 0em;
	padding-right: 1em;
	padding-left: 1em;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(160, 158, 156, 0.75);
	background-color: hsla(0, 0%, 100%, 0.8);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.75em;
	line-height: 1.25em;
	font-weight: 300;
}

.form-field.header-search-field:focus {
	background-color: #fff;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #48a700;
	background-color: #caf3aa;
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
	font-weight: 300;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-right: 6px;
	margin-bottom: 0rem;
	padding-left: 0em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-size: 1rem;
	line-height: 1.25em;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	border-top: 3px solid #aae0fa;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #c9c6c4;
	font-size: 1rem;
}

.left-nav-list-link {
	display: block;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/icon_arrow2_right_blk_25.svg");
	background-position: -10px 50%;
	background-size: 7px 10px;
	background-repeat: no-repeat;
	-webkit-transition: background-color 200ms ease, color 200ms ease, padding 200ms ease;
	transition: background-color 200ms ease, color 200ms ease, padding 200ms ease;
	color: #313131;
	line-height: 1.3rem;
}

.left-nav-list-link:hover {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_6f6e6d.svg");
	background-position: 0px 50%;
	background-size: 7px;
	color: #213a7f;
}

.left-nav-list-link.selected,
.left-nav-list-link.w--current {
	padding-right: 0.4rem;
	padding-left: 0.75em;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_6f6e6d.svg");
	background-position: 0px 50%;
	background-size: 7px;
	background-repeat: no-repeat;
	-webkit-transition-property: none;
	transition-property: none;
	color: #213a7f;
	font-weight: 600;
}

.left-nav-list-link.selected:hover,
.left-nav-list-link.w--current:hover {
	color: #213a7f;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 0.75em;
	border-top: 1px solid #c9c6c4;
	background-color: rgba(0, 0, 0, 0.04);
}

.left-nav-nested-list.great-grandchild-nested-list {
	background-color: rgba(0, 0, 0, 0.03);
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 1px 0 0 #c9c6c4;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	-webkit-transition: padding 200ms ease, background-color 200ms ease, color 200ms ease;
	transition: padding 200ms ease, background-color 200ms ease, color 200ms ease;
	color: #313131;
	font-size: 0.9rem;
	line-height: 1.2rem;
	font-weight: 300;
}

.left-nav-nested-list-link:hover {
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_6f6e6d.svg");
	background-position: 0px 50%;
	background-size: 6px;
	background-repeat: no-repeat;
	color: #213a7f;
}

.left-nav-nested-list-link.selected,
.left-nav-nested-list-link.w--current {
	padding-right: 0.4rem;
	padding-left: 0.75rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_6f6e6d.svg");
	background-position: 0px 50%;
	background-size: 6px;
	background-repeat: no-repeat;
	color: #213a7f;
	font-weight: 600;
}

.left-nav-nested-list-link.selected:hover,
.left-nav-nested-list-link.w--current:hover {
	color: #213a7f;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.slideshow-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	margin-top: 122px;
}

.slider {
	display: block;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-mask {
	padding-bottom: 177px;
	background-color: #fff;
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 3%;
	bottom: 7.5rem;
	display: block;
	width: 3.5rem;
	height: 2.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	border-radius: 0em;
	background-color: rgba(73, 73, 72, 0.85);
	opacity: 0.75;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #fff;
	font-size: 0.875rem;
}

.slider-arrow:hover {
	background-color: #2c2c2b;
	opacity: 1;
	color: #fff;
}

.slider-arrow.left-slider-arrow {
	display: block;
	margin-right: 3.75rem;
	border-style: solid;
	border-width: 1px;
	border-color: hsla(0, 0%, 100%, 0.25);
	background-color: rgba(73, 73, 72, 0.85);
}

.slider-arrow.left-slider-arrow:hover {
	background-color: #2c2c2b;
}

.slider-indicator {
	left: auto;
	right: 3%;
	bottom: 7.5rem;
	display: block;
	width: auto;
	height: 2.5rem;
	margin-right: 7.5rem;
	margin-left: auto;
	padding: 1rem 1rem 0rem;
	border-radius: 5em;
	background-image: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.5rem;
	line-height: 1em;
	text-align: right;
}

.home-section {
	overflow: hidden;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 15vw;
	max-height: 200px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f3f5f7;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/slide-05.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link.large-image-link {
	position: relative;
	overflow: visible;
	height: 18vw;
	max-height: 400px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/placeholder-image.png");
	font-family: 'Roboto Condensed', Rajdhani;
	background-color: #fff;
	color: #fff;
	font-size: 1.75rem;
	line-height: 1em;
	font-weight: 500;
	text-shadow: 0 2px 3px rgba(5, 34, 53, 0.3);
}

.home-image-link.large-image-link.large-image-1 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/fruit.jpg");
	background-size: 100%;
	background-repeat: no-repeat;
	-webkit-transition-duration: 550ms;
	transition-duration: 550ms;
}

.home-image-link.large-image-link.large-image-1:hover {
	background-size: 110%;
}

.home-image-link.large-image-link.large-image-2 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/food.jpg");
	background-size: 100%;
	-webkit-transition-duration: 550ms;
	transition-duration: 550ms;
}

.home-image-link.large-image-link.large-image-2:hover {
	background-size: 110%;
}

.home-image-link.large-image-link.large-image-3 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/breakfast.jpg");
	background-size: 100%;
	-webkit-transition-duration: 550ms;
	transition-duration: 550ms;
}

.home-image-link.large-image-link.large-image-3:hover {
	background-size: 110%;
}

.home-image-link.large-image-link.large-image-4 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/11_578x325.jpg");
}

.home-image-link.large-image-link.large-image-5 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/7_578x325.jpg");
}

.home-image-link.large-image-link.large-image-6 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/1_578x325.jpg");
}

.home-content-box-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.home-content-box-wrap.large-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.home-content-box-title {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.home-content-box-title.intro-cb-title {
	color: #213a7f;
	font-size: 2.5rem;
	line-height: 1.1;
	font-weight: 300;
}

.home-content-box-title.intro-cb-title.lower-cta-title {
	color: #fff;
}

.slider-caption-wrap {
	position: absolute;
	z-index: 5;
	display: block;
	width: 100%;
	max-width: 70%;
	margin-bottom: 1rem;
	padding: 0.5rem 4vw 1rem;
	color: rgba(240, 246, 248, 0.9);
	font-size: 1.125rem;
	line-height: 1.5em;
	text-align: right;
}

.inside-page-header-content-wrap {
	position: relative;
	margin-bottom: 1em;
	padding-bottom: 0em;
	border-bottom: 1px none #d5e1e6;
}

.inside-page-header-section {
	position: relative;
	display: block;
	height: 18.23vw;
	background-color: #c9c6c4;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/fruit.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin-top: 122px;
}

.main-content-wrapper {
	overflow: hidden;
	width: 100%;
}

.main-content-wrapper.no-sidebars {
	font-size: 1.125rem;
}

.intro_paragraph,
.intro-paragraph,
.intro-text-style {
	margin-bottom: 0.75rem;
	color: #313131;
	font-size: 1.35rem;
	line-height: 1.95rem;
}

.home-cb-text-wrap {
	width: 100%;
	padding: 0.5rem 0.125em;
}

.home-cb-text-wrap.intro-cbox-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	margin-right: 2rem;
	padding: 0rem 0em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.0625rem;
}

.home-cb-text-wrap.intro-cbox-text-wrap.reversed {
	margin-right: 0;
	margin-left: 2rem;
}

.home-cb-text-wrap.lower-cta-wrap {
	padding: 3vw 4.5vw 5vw 6vw;
	background-color: rgba(73, 73, 72, 0.85);
}

.slider-caption-title p {
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	float: left;
	color: #213a7f;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 600;
	text-align: left;
	letter-spacing: 0em;
}

.footer-social-icons {
	margin-bottom: 2rem;
}

.footer-social-icons-section-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-social-icon-link {
	margin: 4px 6px 8px;
	border-radius: 50%;
	opacity: 0.7;
}

.footer-social-icon-link:hover {
	opacity: 1;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 4px;
}

.footer-terms-section-wrap ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	font-size: 1rem;
}

.left-nav-wrapper {
	display: block;
	height: auto;
	padding-right: 2.25vw;
	padding-top: 0em;
	padding-bottom: 4vw;
}

.footer-link {
	display: inline;
	border-bottom: 1px solid #797676;
	color: #313131;
}

.footer-link:hover {
	border-bottom-color: transparent;
	color: #213a7f;
}

.text-link {
	border-bottom: 1px solid #797676;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	color: #313131;
	line-height: 1rem;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: rgba(33, 58, 127, 0.59);
}

.text-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link.breadcrumb-link {
	display: inline-block;
	border-bottom-color: transparent;
}

.text-link.breadcrumb-link:hover {
	border-bottom-color: #c5d7df;
	color: #721926;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #c9c6c4;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 10%;
	margin-left: 10%;
}

.nav-drop-wrap {
	position: relative;
	margin-right: 4px;
	margin-left: 4px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	border-right: 1px none rgba(0, 0, 0, 0.15);
	margin: 0;
	padding: 0;
}

.nav-drop-wrap.last-nav-drop-wrap {
	margin-right: 0px;
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	margin-bottom: 0;
}

.nav-drop-toggle:last-of-type .nav-drop-toggle-link {
	width: auto;
	margin-right: 0vw;
	margin-left: 0vw;
	padding: 0.5rem 0.5vw;
	color: #213a7f;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-drop-toggle.w--open {
	box-shadow: none;
}

.nav-drop-icon {
	display: none;
	margin-right: 2rem;
	font-size: 0.75em;
}

.nav-drop-list {
	overflow: hidden;
	top: 100%;
}

.dropdown-ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.open .nav-drop-list,
.nav-drop-list.w--open {
	left: 0px;
	top: 100%;
	padding: 0.5em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: rgba(33, 58, 127, 0.9);
	color: #fff;
	margin: 0;
	height: auto;
	opacity: 1;
}

.nav-drop-toggle:last-of-type.open .nav-drop-list {
	left: auto;
	right: 0;
}

.nav-drop-list {
	display: block;
	height: 0;
	opacity: 0;
	-webkit-transition: opacity 300ms ease 0s; /* Safari */
	transition: opacity 300ms ease 0s;
}

.nav-drop-list-link {
	height: inherit;
	overflow: hidden;
}

.nav-drop-list-link {
	height: 100%;
	width: 100%;
	padding: 0;
}

.nav-drop-list-link a {
	margin-bottom: 1px;
	padding: 0.75em 0.75rem 0.75em 0.5rem;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.375em;
	font-weight: 300;
	display: inline-block;
	width: 100%;
}

.nav-drop-list-link a:hover {
	background-color: rgba(0, 0, 0, 0.3);
	color: hsla(0, 0%, 100%, 0.95);
	font-size: 0.875rem;
	font-weight: 300;
}

.nav-drop-list-link a.w--current {
	padding-right: 0.5rem;
	padding-left: 0.75rem;
	background-color: rgba(0, 0, 0, 0.3);
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_white_75.svg");
	background-position: 0px 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	color: #fff;
}

.nav-drop-list-link a.w--current:hover {
	background-size: 12px 12px;
}

.nav-drop-toggle-link {
	width: auto;
	margin-right: 2vw;
	margin-left: 0vw;
	padding: 0.5rem 0.5vw;
	color: #213a7f;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.open .nav-drop-toggle-link,
.nav-drop-toggle-link:hover {
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
	font-weight: 700;
}

.nav-drop-toggle-link.w--current {
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.header-content-right-row {
	display: block;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.header-search-wrap {
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	background-color: transparent;
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.header-mobile-search {
	display: block;
	overflow: hidden;
	height: 0px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #21272e;
}

.nav-search-button-icon {
	width: 1.125em;
	height: 1.375em;
}

.nav-search-button-close-icon {
	display: none;
	width: 1em;
	height: 1.375em;
}

.footer-social-icons-links-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.footer-signoff-back-top-button {
	margin-left: 1.5rem;
	padding: 1em 2.5em 0.75em 1em;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/Arrow2_up_blk.svg");
	background-position: 75% 50%;
	background-size: 7px 5px;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 1px #797676;
	opacity: 0.65;
	color: #313131;
	font-size: 0.75rem;
	line-height: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
}

.footer-signoff-back-top-button:hover {
	box-shadow: inset 0 0 0 1px #a09e9c;
	opacity: 1;
}

.home-image-link-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.75em;
	padding-bottom: 1.5em;
	padding-left: 0.75em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	opacity: 0.4;
	-webkit-transition: all 550ms ease;
	transition: all 550ms ease;
}

.last-content-box {
	position: relative;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.home-image-link-overlay-arrow {
	position: relative;
	display: none;
	width: 1em;
	height: 1em;
	padding: 4px;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/Arrow3_right_blk.svg");
	background-position: 50% 50%;
	background-size: 65% 65%;
	background-repeat: no-repeat;
	opacity: 0.5;
}

.home-image-link-text {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	padding: 0.75em 14% 0.75em 7%;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-color: transparent;
	background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.85)), color-stop(64%, rgba(9, 49, 76, 0)));
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.85), rgba(9, 49, 76, 0) 64%);
	box-shadow: inset 0 0 25px 0 rgba(0, 0, 0, 0.1);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #fff;
	font-size: 1.75rem;
	line-height: 2rem;
	font-weight: 300;
}

.home-image-link-text:hover {
	padding-bottom: 1.5em;
	background-color: rgba(0, 0, 0, 0.5);
}

.footer-contact-label-span {
	display: inline-block;
	min-width: 40px;
}

.slide-caption-link {
	padding-right: 1rem;
	float: left;
	clear: both;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/angle-right_6f6e6d.svg");
	background-position: 100% 50%;
	background-size: 8px;
	background-repeat: no-repeat;
	color: #6b6b6b;
	font-size: 1.1rem;
	line-height: 1.5rem;
	text-align: left;
	text-transform: uppercase;
}

.slide-caption-link:hover {
	padding-right: 1.5rem;
	color: #213a7f;
}

.h2 {
	font-family: 'Roboto Condensed', Nunito;
	line-height: 1.25em;
}

.cb-h2 {
	color: #213a7f;
	font-size: 1.75rem;
	font-weight: 300;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
}

.cta-paragraph p {
	max-width: 1100px;
	font-size: 1.25rem;
	line-height: 1.75rem;
}

.cta-paragraph.lower-cta-paragraph {
	color: #fff;
}

.text-block-2 {
	color: #313131;
	font-weight: 600;
}

.footer-paragraph {
	color: #313131;
}

.footer-paragraph.phone-paragraph {
	margin-bottom: 0.25rem;
}

.slide-image {
	display: block;
	width: 100%;
	max-height: 650px;
	height: 34vw;
	background-position: 90% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.inside-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2rem;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: transparent;
}

.content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.header-link {
	border-bottom: 1px solid #797676;
	color: #721926;
}

.header-link:hover {
	border-bottom-color: transparent;
	color: #797676;
}

.header-link.h1-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h1-link:hover {
	color: #797676;
}

.header-link.h2-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h2-link:hover {
	color: #797676;
}

.header-link.h3-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h3-link:hover {
	color: #797676;
}

.header-link.h4-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h4-link:hover {
	color: #797676;
}

.header-link.h5-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h5-link:hover {
	color: #797676;
}

.header-link.h6-link {
	border-bottom-color: #aae0fa;
	color: #213a7f;
}

.header-link.h6-link:hover {
	color: #797676;
}

.phone-link {
	color: #313131;
}

.phone-link.w--current {
	color: #313131;
}

.ecomm-header-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 1rem;
	padding-left: 0px;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/user_fa_6f6e6d.svg");
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: #313131;
	font-size: 0.6875rem;
	line-height: 1.125em;
	font-weight: 600;
	text-align: center;
	text-transform: uppercase;
}

.ecomm-header-link:hover {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/user_fa_d91b0c.svg");
	background-size: 20px 20px;
	color: #721926;
}

.ecomm-header-link.cart-icon {
	padding-left: 24px;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/shopping-cart_fa_797676.svg");
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	-webkit-transition-property: color;
	transition-property: color;
	color: #6b6b6b;
}

.ecomm-header-link.cart-icon:hover {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/shopping-cart_fa_721926.svg");
	background-position: 0px 50%;
	background-size: 20px 20px;
	background-repeat: no-repeat;
	color: #721926;
}

.ecomm-header-link.account-icon {
	width: 25px;
	height: 22px;
	margin-right: 1rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/user_fa_797676.svg");
	background-position: 50% 50%;
	background-size: 20px;
	-webkit-transition-property: color;
	transition-property: color;
}

.ecomm-header-link.account-icon:hover {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/user_fa_721926.svg");
	background-size: 20px 20px;
}

.ecomm-link-text {
	padding-top: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.4rem;
	float: left;
	font-size: 0.773rem;
	font-weight: 600;
}

.ecomm-link-text:hover {
	color: #721926;
}

.ecomm-header-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-quick-link {
	display: inline-block;
	border-bottom: 1px solid transparent;
	color: #3e3f3d;
	line-height: 1;
	text-transform: capitalize;
}

.footer-quick-link:hover {
	border-bottom: 1px solid #797676;
}

.footer-quick-link.w--current {
	border-bottom-color: #797676;
}

.top {
	overflow: visible;
	width: 100%;
	height: 0px;
}

.text-block-5 {
	color: #797676;
}

.slide-image-wrap {
	width: 100%;
	height: 100%;
}

.home-content-box-title-2 {
	margin-top: 0rem;
	margin-bottom: 0.25em;
	padding-top: 0.125em;
	font-size: 1.625em;
	line-height: 1.125em;
	text-transform: uppercase;
}

.home-content-box-title-2.intro-cbox-title {
	padding-top: 0.125em;
	font-size: 2.25em;
	line-height: 1.125em;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.home-cbox-learn-more {
	position: absolute;
	left: 0px;
	bottom: 0px;
	z-index: 500;
	display: inline-block;
	margin-top: 0rem;
	padding-top: 0.38rem;
	padding-bottom: 0.25rem;
	border-bottom: 1px solid transparent;
	color: #163a59;
	font-size: 1rem;
	line-height: 1rem;
	font-weight: 700;
	letter-spacing: 0.03rem;
	text-transform: uppercase;
}

.home-cbox-learn-more:hover {
	border-bottom: 1px solid #797676;
	color: #3e3f3d;
}

.home-cbox-learn-more.intro-cbox-learn-more {
	position: relative;
	padding: 0.65rem 1.5rem 0.55rem;
	border-bottom-style: none;
	background-color: #fff;
	font-size: 1em;
	font-weight: 600;
	letter-spacing: 0.07em;
	text-shadow: none;
}

.home-cbox-learn-more.intro-cbox-learn-more:hover {
	border-bottom-style: none;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
}

.home-cbox-text-wrap {
	position: relative;
	padding-top: 1.125em;
	padding-bottom: 2rem;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.intro-cbox-text-wrap {
	display: block;
	max-width: 70%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 1em;
	padding-bottom: 1em;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	color: hsla(0, 0%, 100%, 0.85);
	font-size: 1.5rem;
	line-height: 1.5em;
	font-weight: 600;
	text-align: center;
	letter-spacing: 0.03em;
	text-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
}

.paragraph {
	font-weight: 300;
}

.container-2 {
	position: relative;
	max-width: 1200px;
	padding-right: 25px;
	padding-left: 25px;
}

.link {
	border-bottom: 1px solid #797676;
	font-weight: 500;
}

.link:hover {
	border-bottom-color: transparent;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.content-box-title-link {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #163a59;
}

.link.content-box-title-link:hover {
	border-bottom-color: #797676;
	color: #3e3f3d;
}

.link.content-box-title-link.intro-cbox-title-link {
	display: inline-block;
	padding-top: 0.125em;
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #fff;
}

.link.content-box-title-link.intro-cbox-title-link:hover {
	border-bottom-color: transparent;
	color: #fff;
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.column-2 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.home-content-box-wrap-2 {
	position: relative;
}

.home-content-box-wrap-2.intro-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.intro-cbox-section {
	margin-top: 4em;
	margin-bottom: 3em;
	padding-top: 3em;
	padding-bottom: 3em;
	background-color: #e4e7eb;
	background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(22, 58, 89, 0.7)), to(rgba(22, 58, 89, 0.7))), -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))), url("/20200408172800/local/public/shared/assets/images/websites/LisleGroup2013.jpg");
	background-image: linear-gradient(180deg, rgba(22, 58, 89, 0.7), rgba(22, 58, 89, 0.7)), linear-gradient(180deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("/20200408172800/local/public/shared/assets/images/websites/LisleGroup2013.jpg");
	background-position: 0px 0px, 0px 0px, 50% 50%;
	background-size: auto, auto, cover;
	background-repeat: repeat, repeat, no-repeat;
	background-attachment: scroll, scroll, fixed;
}

.fixed-image {
	position: fixed;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/11.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.div-block {
	position: absolute;
}

.language {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: 0px;
	margin-left: 10px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	transition: all 0.3s ease;
	border-bottom: 2px solid transparent;
	color: #797676;
}

.language:hover {
	border-bottom: 2px solid #213a7f;
	color: #313131;
}

.language .language-text {
	font-size: 1rem;
	line-height: 1.4;
	text-transform: uppercase;
}

.language img {
	width: 25px;
}

.contact-link {
	display: none;
	width: auto;
	margin-right: 20px;
	padding: 0rem 0vw;
	color: #213a7f;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.contact-link:hover {
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
	font-weight: 700;
}

.contact-link.w--current {
	border-bottom: 1px none #aae0fa;
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
}

.header-social {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.header-social-icon {
	width: 30px;
	height: 30px;
	padding: 4px;
}

.header-social-link {
	margin: 0px 10px 0px 0px;
	border-radius: 50%;
	opacity: 0.7;
}

.header-social-link:hover {
	opacity: 1;
}

.div-block-2 {
	width: 60%;
}

.heading {
	margin-top: 0.4rem;
	margin-bottom: 0rem;
	color: #213a7f;
	font-size: 1.5rem;
	line-height: 1.75rem;
	font-weight: 600;
}

.paragraph-2 {
	margin-top: 0.4rem;
	font-style: normal;
}

#news_module .cms_list_item .cms_description p,
.paragraph-extra-lineheight {
	margin-bottom: 0rem;
	line-height: 1.3;
	margin-bottom: 10px;
	margin-top: 10px;
	font-size: 1.125rem;
}

.paragraph-extra-lineheight.sponsor-paragraph {
	margin-right: 1rem;
}

.spotlight-content-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: stretch;
	-ms-flex-line-pack: stretch;
	align-content: stretch;
}

.cb-subhead {
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	color: #2d3047;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 300;
	text-decoration: none;
}

.cb-subhead:hover {
	color: #213a7f;
}

.cb-bkgd-image {
	height: 265px;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/516450790.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.cb-bkgd-image.blog-image {
	display: none;
}

.cb-bkgd-image.sponsor-image {
	display: none;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/DSC_0147.JPG");
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #0a706b;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 300;
}

.cb-heading.cb-heading-secondary {
	color: #0a706b;
}

.header-text-link {
	display: inline;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #213a7f;
	font-size: 1.3rem;
	line-height: 1.6rem;
	text-decoration: none;
}

.header-text-link:hover {
	color: #213a7f;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static._50percent-left {
	width: 50%;
	padding-top: 0rem;
	padding-right: 3rem;
	padding-bottom: 0rem;
}

.cb-static._50percent-right {
	width: 50%;
	margin-right: 0px;
	padding: 0rem 60px 0rem 0rem;
}

.post-date {
	margin-top: 0rem;
	margin-bottom: 0rem;
	color: #213a7f;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
}

.home-content-section {
	display: block;
	padding-top: 3rem;
	padding-bottom: 5rem;
	background-color: #fff;
}

.home-content-section.two-cb-home-content-section {
	padding-top: 1rem;
	padding-bottom: 2.25rem;
	border-top: 2px none #e2e2e2;
	background-color: #f6f6f6;
}

.home-content-section.sponsors-section {
	padding-bottom: 5rem;
	border-top: 6px solid #213a7f;
	background-color: #fff;
}

.button-2 {
	display: inline-block;
	height: 44px;
	min-width: 100px;
	margin-top: 1rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 16px;
	border-radius: 2px;
	background-color: #0a706b;
	color: #fff;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.button-2:hover {
	background-color: #06413d;
	background-image: none;
}

.button-2:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.container-3 {
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.home-content-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: none;
	padding-right: 4vw;
	padding-left: 4vw;
	-webkit-justify-content: space-around;
	-ms-flex-pack: distribute;
	justify-content: space-around;
}

.news-button {
	display: inline-block;
	height: auto;
	min-width: 100px;
	margin-top: 0.4rem;
	margin-right: 1.5rem;
	margin-bottom: 1rem;
	padding: 9px 10px;
	border-radius: 2px;
	background-color: #213a7f;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.6;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
}

.news-button:hover {
	background-color: rgba(33, 58, 127, 0.15);
	background-image: none;
	color: #213a7f;
}

.news-button:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.feature-cb-paragraph a,
.textlink-middleout {
	color: #213a7f;
	font-weight: 300;
	text-decoration: none;
}

.feature-cb-paragraph a:hover,
.textlink-middleout:hover {
	box-shadow: inset 0 -1px 0 0 #629edc;
}

.insta-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 520px;
	height: 520px;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-color: #000;
}

.cb-feature-heading {
	margin-top: 0rem;
	color: #213a7f;
	font-size: 3rem;
	line-height: 3.5rem;
}

.container-4 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.home-content-section-2 {
	display: block;
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.home-content-section-2.hcs-row-1 {
	padding-bottom: 7rem;
	background-color: #fff;
	text-align: left;
}

.feature-cb-paragraph p {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	font-size: 1.25rem;
	line-height: 2.1rem;
	width: 100%;
	word-break: break-word;
}

.cb-header-wrap {
	display: block;
	margin-right: auto;
	margin-left: auto;
	padding-right: 2rem;
	padding-left: 2rem;
	text-align: center;
}

.insta-tile-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	border: 1px solid #fff;
	background-image: url("https://d3e54v103j8qbb.cloudfront.net/img/example-bg.png");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	box-shadow: 0 0 9px -8px rgba(0, 0, 0, 0.5);
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	text-decoration: none;
}

.insta-tile-link:hover {
	box-shadow: none;
	opacity: 0.6;
}

.insta-tile-link.insta-3 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/insta-4.jpg");
}

.insta-tile-link.insta-4 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/insta-5.jpg");
}

.insta-tile-link.big-pic {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/insta-1.jpg");
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.insta-tile-link.insta-2 {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/insta-3.jpg");
}

.insta-tile-link.insta-1 {
	width: 100%;
	height: 100%;
	border-color: #fff;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/insta-2.jpg");
}

.insta-small-image-wrap {
	width: 260px;
	height: 260px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.instagram-wrapper {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	margin-top: 2rem;
	margin-right: auto;
	margin-left: auto;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	padding: 0 4vw;
}

.cb-heading-2 {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 3rem;
	color: #163375;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 300;
}

.cb-heading-2.clients-cb-head {
	display: block;
	margin-right: auto;
	margin-left: auto;
	color: #213a7f;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 300;
	text-align: center;
}

.clients-slider-arrow-icon {
	color: #213a7f;
}

.cb-head-wrapper {
	display: block;
	margin-top: 2rem;
	margin-bottom: 1rem;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.clients-slider-slide {
	position: relative;
	width: 20%;
	height: 60px;
	min-width: 100px;
	margin-top: 10px;
}

.clients-slider-slide.slide-1 {
	border-left: 1px solid rgba(111, 124, 136, 0.07);
}

.clients-slider {
	position: relative;
	height: 80px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	border-style: none;
	border-width: 1px;
	border-color: rgba(111, 124, 136, 0.15);
	background-color: transparent;
}

.clients-slider-arrow {
	z-index: 21;
	height: 100%;
	max-width: 2rem;
	-webkit-transition: background-color 300ms ease, color 300ms ease;
	transition: background-color 300ms ease, color 300ms ease;
	color: #01579b;
	font-size: 1.25rem;
}

.clients-slider-arrow:hover {
	background-color: rgba(33, 58, 127, 0.15);
	color: #1f304f;
}

.clients-slider-image {
	position: relative;
	top: 50%;
	display: block;
	max-height: 50px;
	max-width: 100px;
	margin-right: auto;
	margin-left: auto;
	-webkit-transform: translate(0px, -50%);
	-ms-transform: translate(0px, -50%);
	transform: translate(0px, -50%);
}

.clients-slider-mask {
	position: relative;
	height: 100%;
	padding-right: 2rem;
	padding-left: 2rem;
}

.share-button {
	position: static;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: block;
	width: 50px;
	height: 50px;
	margin-right: 4vw;
	margin-bottom: 20px;
	border-radius: 100%;
	background-color: #213a7f;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/share-icon.svg");
	background-position: 50% 50%;
	background-size: 50%;
	background-repeat: no-repeat;
	box-shadow: none;
}

.share-button:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/globalreach_dark.svg");
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.gray-png {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/globalreach_grey.png");
	background-size: 130px 29px;
}

.grip-logo-text-box {
	color: #000;
	font-size: 0.5rem;
	line-height: 0.5rem;
	font-weight: 700;
	letter-spacing: 0.01rem;
	text-decoration: none;
	text-transform: uppercase;
}

.grip-logo-text-box.webdevby-gray {
	color: #797676;
}

.nav-drop-toggle-link-end {
	width: auto;
	margin-right: 0vw;
	margin-left: 0vw;
	padding: 0.5rem 0.5vw;
	color: #213a7f;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-drop-toggle-link-end:hover {
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
	font-weight: 700;
}

.nav-drop-toggle-link-end.w--current {
	box-shadow: inset 0 -2px 0 0 #aae0fa;
	color: #213a7f;
}

.section-2 {
	position: -webkit-sticky;
	position: sticky;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-top: -69.28128px;
	padding-bottom: 2vw;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.gear {
	position: static;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	width: 50px;
	height: 50px;
	margin-right: 4vw;
	margin-bottom: 0px;
	border-radius: 100%;
	background-color: #000;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/gear-icon.png");
	background-position: 0px 0px;
	background-size: contain;
	background-repeat: no-repeat;
	box-shadow: none;
}

.gear:hover {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}

.div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.div-block-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.div-block-5 {
	position: fixed;
	left: auto;
	top: auto;
	right: 0%;
	bottom: 0%;
	z-index: 1000;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 300px;
	margin-bottom: 80px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.navigation-link {
	padding: 1.5rem 1.3rem;
	float: none;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	border-right: 1px none hsla(0, 0%, 94.5%, 0.3);
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/navy-tri.svg");
	background-position: 50% 120%;
	background-size: 12px 11px;
	background-repeat: no-repeat;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #012b5d;
	font-size: 1rem;
	line-height: 1rem;
	text-align: center;
}

.navigation-link:hover {
	background-position: 50% 97%;
	box-shadow: inset 0 -4px 0 0 #012b5d;
}

.navigation-link.w--current {
	background-position: 50% 97%;
	background-size: 12px 11px;
	box-shadow: inset 0 -4px 0 0 #012b5d;
	color: #012b5d;
}

.navigation-link.search-dd {
	width: 20px;
	height: 20px;
	margin-right: 1rem;
	padding: 0rem;
	float: none;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/search_navy.svg");
	background-position: 0px 0px;
	background-size: contain;
}

.navigation-link.search-dd:hover {
	background-image: url("/20200408172800/local/public/shared/assets/images/websites/search_navy.svg");
	background-position: 0px 0px;
	background-size: contain;
	box-shadow: none;
}

.header-search-container {
	position: absolute;
	left: 0%;
	top: 122px;
	right: 0%;
	bottom: auto;
	z-index: 1000;
	display: block;
	overflow: hidden;
	height: 0;
	max-width: none;
	margin-right: auto;
	margin-left: auto;
}

.search-form-wrap {
	position: relative;
	z-index: 1000;
	display: block;
	width: 460px;
	padding-top: 1rem;
	padding-right: 1rem;
	padding-left: 1rem;
	float: right;
	background-color: #fff;
}

.search-form {
	height: 2.5rem;
}

.search-field {
	width: 75%;
	height: 2.5rem;
	float: left;
}

.search-link {
	width: 25%;
	height: 2.5rem;
	float: right;
	background-color: #213a7f;
}

.mobile-search-link {
	display: inline-block;
}

.mobile-social {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.recipes-button {
	display: inline-block;
	height: auto;
	min-width: 89px;
	margin-top: 0rem;
	margin-right: 1rem;
	margin-bottom: 0rem;
	padding: 5px 10px;
	border-radius: 2px;
	background-color: #006536;
	color: #fff;
	font-size: 0.9rem;
	line-height: 1.6;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.recipes-button:hover {
	background-color: rgba(0, 101, 54, 0.15);
	background-image: none;
	color: #006536;
}

.recipes-button:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.heading-2 {
	color: #213a7f;
}

.heading-3 {
	color: #213a7f;
}

.heading-4 {
	color: #213a7f;
}

.heading-5 {
	color: #213a7f;
}

.heading-6 {
	color: #213a7f;
}

.heading-7 {
	color: #213a7f;
}

.block-quote {
	border-left-color: #213a7f;
	background-color: rgba(170, 224, 250, 0.36);
}

.heading-8 {
	color: #213a7f;
}

.heading-9 {
	color: #213a7f;
}

.heading-10 {
	color: #213a7f;
}

.heading-11 {
	color: #213a7f;
}

.heading-12 {
	color: #213a7f;
}

.heading-13 {
	color: #213a7f;
}

.heading-14 {
	color: #213a7f;
}

.heading-15 {
	color: #213a7f;
}

.heading-16 {
	color: #213a7f;
}

.heading-17 {
	color: #213a7f;
}

.heading-18 {
	color: #213a7f;
}

.heading-19 {
	color: #213a7f;
}

.heading-20 {
	color: #213a7f;
}

.heading-21 {
	color: #213a7f;
}

.heading-22 {
	color: #213a7f;
}

.heading-23 {
	color: #213a7f;
}

.heading-24 {
	color: #213a7f;
}

.heading-25 {
	color: #213a7f;
}

.heading-26 {
	color: #213a7f;
}

.heading-27 {
	color: #213a7f;
}

.heading-28 {
	color: #213a7f;
}

.heading-29 {
	color: #213a7f;
}

.heading-30 {
	color: #213a7f;
}

.heading-31 {
	color: #213a7f;
}

.heading-32 {
	color: #213a7f;
}

.heading-33 {
	color: #213a7f;
}

.contact-button {
	display: inline-block;
	height: auto;
	min-width: 101px;
	margin-top: 0rem;
	margin-right: 1rem;
	margin-bottom: 0rem;
	padding: 5px 10px;
	border-radius: 2px;
	color: #213a7f;
	font-size: 0.9rem;
	line-height: 1.6;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
}

.contact-button:hover {
	background-color: rgba(33, 58, 127, 0.15);
	background-image: none;
	color: #213a7f;
}

.contact-button:active {
	background-image: none;
	box-shadow: inset 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.750000000000001;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="nav-section-on-page-load"] {
	-webkit-transform: translate(0px, -50px);
	-ms-transform: translate(0px, -50px);
	transform: translate(0px, -50px);
}

html.w-mod-js *[data-ix="nav-container-on-load"] {
	height: 60px;
}

html.w-mod-js *[data-ix="header-contents-wrap-on-page-load"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="home-scroll-animation"] {
	opacity: 0;
}

html.w-mod-js *[data-ix="logo-image-on-load"] {
	width: 100%;
}

html.w-mod-js *[data-ix="logo-image-sticky"] {
	-webkit-transform: translate(0px, -100px);
	-ms-transform: translate(0px, -100px);
	transform: translate(0px, -100px);
}

html.w-mod-js *[data-ix="slidecaptionheader-2"] {
	opacity: 0;
	-webkit-transform: scale(0.7000000000000005, 0.7000000000000005);
	-ms-transform: scale(0.7000000000000005, 0.7000000000000005);
	transform: scale(0.7000000000000005, 0.7000000000000005);
}

html.w-mod-js *[data-ix="slide-learn-more"] {
	opacity: 0;
}

.w-video.w-embed {
	position: relative;
	overflow: hidden;
	padding-top: 56.25%;
}

.w-video.w-embed iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.contact-button.horeca {
	min-width: 70px;
}

/*mmenu*/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (max-width: 991px) {
	.mm-menu {
		background: #213a7f;
	}

	.navigation-menu {
		top: 0;
		margin-top: 0;
		width: 80%;
	}

	.mobile-navigation-menu,
	.desktop-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		max-width: none;
		display: block !important;
	}

	.mm-menu_offcanvas {
		max-width: none;
	}

	.mm-panel {
		padding: 0;
	}

	.mm-panels > .mm-panel > .mm-listview {
		margin: -20px 0 20px;
	}

	.mm-panels > .mm-panel + .mm-panel > .mm-listview {
		margin-top: 41px;
	}

	.mm-menu li {
		display: block !important;
	}

	.mm-menu a,
	.mm-menu a:active,
	.mm-menu a:link,
	.mm-menu a:visited {
		color: #fff;
		padding: 1.25em 1.375rem;
		float: none;
		box-shadow: none;
		border-bottom: 1px solid #596b9f;
		text-transform: capitalize;
	}

	.mm-menu .active > a:not(.mm-btn_next),
	.mm-menu .active > span,
	.mm-menu .mm-listitem_selected > a:not(.mm-btn_next),
	.mm-menu .mm-listitem_selected > span {
		background-color: #172859;
		cursor: pointer;
		box-shadow: inset 5px 0 0 0 #fff;
	}

	.mm-menu a.w--current {
		background-color: #87aed666;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu a:hover {
		background-color: #172859;
		color: #fff;
		cursor: pointer;
	}

	.mm-listitem:after {
		border-bottom-width: 0px;
	}

	.mm-menu .mm-btn_prev:hover,
	.mm-menu .mm-navbar__title:hover,
	.mm-menu .mm-btn_next:hover {
		color: #c9c6c4;
		background-color: transparent;
		cursor: pointer;
	}

	.mm-listitem .mm-btn_next:not(.mm-btn_fullwidth) {
		width: 20%;
	}

	.mm-listitem .mm-btn_next:before {
		border-left-width: 1px;
		border-left-color: rgba(255, 255, 255, 0.25);
	}

	.mm-menu .mm-btn:after,
	.mm-menu .mm-btn:before,
	.mm-menu .mm-listview .mm-btn_next:after {
		border-color: #fff;
		-webkit-transition: border-color 300ms;
		transition: border-color 300ms;
	}

	.mm-menu .mm-btn:hover:after,
	.mm-menu .mm-btn:hover:before,
	.mm-menu .mm-listview .mm-btn_next:hover:after {
		border-color: #fff;
	}

	.mm-btn_next:after,
	.mm-btn_prev:before {
		width: 9px;
		height: 9px;
	}

	.mm-btn_next:after {
		right: 46%;
	}

	.mm-btn_prev:before {
		margin-left: 0;
	}

	.mm-menu .mm-listitem .mm-btn_next {
		background: #1c316c;
	}

	.mm-menu .mm-listview .mm-btn_next:hover {
		background-color: #172859;
		color: #fff;
		cursor: pointer;
	}

	.mm-menu .mm-listview .mm-btn_next + a {
		width: 80%;
		margin-right: 20%;
	}

	.mm-navbar {
		border-bottom: 1px solid #596b9f;
		padding: 1px 10px 0 40px;
		height: 61px;
		margin-right: 0;
	}

	.mm-menu .mm-navbar a,
	.mm-menu .mm-navbar > * {
		transition: color 300ms;
		height: 61px;
		border-bottom: 0 none !important;
	}

	.mm-menu a.mm-navbar__title {
		font-size: 1.125rem;
		padding: 1.1em 1.375rem;
		line-height: 1;
		font-weight: 300;
		text-align: left;
		padding-left: 8px !important;
		text-transform: capitalize;
		border-bottom: 0 none;
	}

	.mm-navbar:hover {
		cursor: pointer;
		background-color: #172859;
	}

	.mm-navbars_top .mm-navbar {
		background: transparent;
		height: 50px;
		border-bottom: 1px solid #596b9f;
	}

	.mm-menu_navbar_top-1 .mm-panels {
		top: 50px;
	}

	.mm-menu .mm-navbar .div-block-6 {
		height: 50px;
		cursor: default;
	}

	.mm-menu .div-block-6 .language.language-mobile {
		padding: 0;
		height: auto;
	}

	.mm-menu .div-block-6 .language.language-mobile:hover {
		background-color: transparent;
	}

	.mm-menu .mm-navbar .div-block-7 a {
		width: 30px;
		height: 30px;
		padding: 0;
	}

	.mm-menu .mm-navbar .div-block-7 a:hover {
		background-color: #fff;
	}

	.mm-menu .mm-navbar .div-block-7 a img {
		width: 70%;
	}

	.mm-menu .mm-navbar:hover a,
	.mm-menu .mm-navbar:hover a:before {
		color: #fff;
		border-color: #fff;
	}

	.instagram-wrapper {
		padding: 0 30px;
	}
}
/* end of mmenu */

/* Phone links */
.phone-mobile {
	display: none;
}

@media (max-width: 991px) {
	.phone-mobile {
		display: inline-block;
	}

	.phone-desktop {
		display: none;
	}
}
/* end of Phone links */

/* session messages */
#session-message-wrapper .success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #48a700;
	background-color: #caf3aa;
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
}

#session-message-wrapper .error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	border: 2px solid #721926;
	background-color: #ffccd0;
	font-family: 'Roboto Condensed', Assistant, sans-serif;
	color: #000;
	font-size: 1.125rem;
	font-weight: 300;
}

#session-message-wrapper .success p,
#session-message-wrapper .success ul li,
#session-message-wrapper .error p,
#session-message-wrapper .error ul li {
	margin-top: 0;
	font-weight: 300;
	font-size: 1.125rem;
	color: #000;
}

.success :last-child,
.error :last-child {
	margin-bottom: 0;
}
/* end of session messages */

/* styled tables */
/* Overall Table Characteristics */
.styled {
	width: 100%;
	margin: 0 0 0.75rem 0;
	background-color: white;
	border-spacing: 0px;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px;
	line-height: 1.125em;
}

/* Vertical Cell Borders */
.styled th,
.styled td {
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	vertical-align: top;
}

.styled tr th:last-child,
.styled tr td:last-child {
	border-right: none;
}

/* Table Header - aka Table Title */
.styled thead tr th {
	font-weight: 300;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #213a7f;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
}

/* Optional Column Headers */
.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #313131;
	border-bottom: 0px solid white;
	color: white;
	text-align: left;
}

/* This code controls any visual distinctions for link decoration */
.styled th a {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border: 0;
}

.styled th a:hover {
	color: inherit;
	font-weight: inherit;
	text-decoration: none;
}

/* Overall Data Characteristics */
.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
}

/* Even Row Data */
.styled.striped tr:nth-child(even) {
	background: #eee;
}

/* Odd Row Data */
.styled.striped tr:nth-child(odd) {
	background: white;
}
/* end of styled tables */

/* inside page layout */
.inside-content-column.with-left-sidebar .main-content-wrapper,
.inside-content-column.full-width,
.inside-content-column.with-right-sidebar {
	width: 100%;
}

.inside-content-column.with-left-sidebar {
	width: 80%;
}

.inside-content-column.both-sidebars {
	width: 80%;
}

.inside-content-column.both-sidebars .main-content-wrapper {
	width: 80%;
}

@media screen and (max-width: 991px) {
	.inside-content-column.both-sidebars .main-content-wrapper,
	.inside-content-column.both-sidebars,
	.inside-content-column.with-left-sidebar {
		width: 100%;
	}
}
/* end of inside page layout */

/* right sidebar */
.right-side-col .box {
	position: relative;
	margin-bottom: 1.5rem;
	margin-left: 0vw;
	padding-top: 0.75rem;
	border-top: 3px solid #aae0fa;
}

.right-side-col .box .contentbox_item {
	margin-bottom: 15px;
}

.right-side-col .contentbox_item .contentbox_item_image {
	width: 32px;
}

.right-side-col td img {
	max-width: none;
}

.right-side-col a {
	border-bottom: 1px solid #797676;
	-webkit-transition-duration: 200ms;
	transition-duration: 200ms;
	color: #313131;
	font-size: 1rem;
	line-height: 1.4em;
	font-weight: 300;
}

.right-side-col a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: rgba(33, 58, 127, 0.63);
}

.right-side-col a.w--current {
	color: #28313b;
	font-weight: 700;
}

.right-side-col {
	width: 21%;
	margin-left: 4%;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
}

.right-side-col h2 {
	margin-top: 0.25rem;
	color: #213a7f;
	font-size: 1.4rem;
	line-height: 1.2;
	font-weight: 600;
}

.right-side-col p {
	font-size: 1rem;
	line-height: 1.3;
}

.right-side-col h3 {
	margin-top: 0.25rem;
	color: #213a7f;
	font-size: 1.4rem;
	line-height: 1.2;
	font-weight: 600;
}

.right-side-col h4 {
	margin-top: 0.25rem;
	color: #213a7f;
	font-size: 1.4rem;
	line-height: 1.2;
}

@media screen and (max-width: 991px) {
	.right-side-col {
		width: 100%;
		margin-top: 1.5rem;
		margin-left: 0%;
	}

	.right-side-col .box {
		margin-left: 0em;
	}
}

@media screen and (max-width: 767px) {
	.right-side-col .box {
		margin-left: 0rem;
	}
}
/* end of right sidebar */

/*forms*/
.cms_label,
.label,
label {
	font-weight: 300;
}

label {
	display: inline;
	margin-bottom: 0;
}

input[type="text"],
input[type="tel"],
input[type="password"],
input[type="email"],
input[type="search"],
textarea,
select {
	display: block;
	padding: 8px 12px;
	margin-bottom: 10px;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #919191;
}

textarea {
	resize: vertical;
}
/* end of forms */

.lightwidget-widget {
	width: 100%;
	border: 0;
	overflow: hidden;
}

/* sticky header */
.header-section {
	z-index: 1100;
	overflow: visible;
	height: auto;
	border-bottom: 1px solid #213a7f;
	background-color: #fff;
	position: fixed;
	top: 0px;
	width: 100%;
}

.mm-page.mm-slideout {
	position: absolute;
	left: 0;
	width: 100%;
	height: 100vh;
	transition: left 350ms ease;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-page__blocker.mm-slideout {
	position: fixed;
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
	transform: none;
	left: -80%;
}

/* in case you have a fixed background image */
.inside-page-header-section {
	transition: all 350ms ease;
}

.mm-wrapper_opening .inside-page-header-section {
	transform: translateX(-80%);
}

#cookie-consent-container {
	transition: all 0.35s ease;
}

.mm-wrapper_opening #cookie-consent-container {
	left: -80%;
}

.mm-wrapper_opening #cookie-consent-container.minimized {
	right: 85%;
	left: auto;
}
/* end of sticky header */

/* modules */
/* locations */
#locations_module.list .location-item {
	width: 100%;
}

#locations_module.list .location-item {
	display: flex;
}

.alert-wrap {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 2.25rem 4rem 2.5rem 3rem;
	background-color: #001982;
	color: #fff;
	display: none;
}

.alert-close-2 {
	position: absolute;
	top: 8px;
	right: 0;
	width: 60px;
	height: 60px;
	margin-right: 1rem;
	background-image: url('/20170713122722/local/public/shared/assets/images/websites/close_white.svg');
	background-position: 50% 50%;
	background-size: 28px 28px;
	background-repeat: no-repeat;
	-webkit-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background-color: transparent;
	cursor: pointer;
}

.alert-wrap h1 {
	margin-top: 0rem;
	color: #fff;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 600;
	text-transform: none;
}

.alert-wrap p {
	margin-top: 16px;
	color: #fff;
	font-size: 1.1rem;
}

.alert-wrap .button {
	height: auto;
	margin-bottom: 0rem;
	padding-top: 0.6rem !important;
	padding-bottom: 0.6rem !important;
	font-size: 0.88rem !important;
	margin-top: 1rem;
}

.alert-wrap .button:hover {
	font-size: 0.88rem !important;
}

.alert-wrap td {
	padding-left: 10px;
	padding-right: 10px;
	vertical-align: top;
}

.alert-wrap td:first-child {
	padding-right: 20px;
	padding-left: 0px;
}

.alert-wrap td p:last-child {
	margin-bottom: 0px;
}

.alert-wrap a {
	color: #fff;
	border-bottom: 1px solid #629edc;
}

.alert-wrap a:hover {
	border-bottom-color: transparent;
}

@media (max-width: 991px) {
	.alert-wrap {
		padding-top: 1.5rem;
		padding-right: 4.5rem;
	}

	.alert-close-2 {
		top: 8px;
	}

	.alert-wrap h1 {
		margin-bottom: 0.5rem;
		font-size: 1.75rem;
		line-height: 2.75rem;
	}

	.alert-wrap p {
		margin-top: 0px;
	}

	.alert-wrap td {
		display: inline-block;
		width: 100% !important;
		text-align: left;
		padding-left: 0px;
		padding-right: 10px;
	}
}

@media (max-width: 767px) {
	.alert-wrap {
		padding-left: 2rem;
	}

	.alert-close-2 {
		top: 0;
		right: -12px;
		background-size: 24px 24px;
	}

	.alert-wrap p {
		font-size: 1rem;
		line-height: 1.6rem;
	}
}

@media (max-width: 479px) {
	.alert-wrap {
		padding-top: 2.75rem;
		padding-right: 2rem;
		order: 2;
	}

	.alert-close-2 {
		background-size: 24px 24px;
	}
}

/* media queries */
@media screen and (max-width: 1300px) {
	.container.inside-container {
		padding: 1vw 4vw 3vw;
	}
}

@media screen and (max-width: 991px) {
	h1 {
		font-size: 2.25rem;
		line-height: 2.75rem;
	}

	.button.header-search-button {
		position: relative;
		z-index: 100;
		width: 20%;
		height: 1.9rem;
		margin-right: 0rem;
		font-size: 1rem;
	}

	.container.home-cb-container {
		padding: 1vw 30px 4vw;
	}

	.container.home-cb-container.home-intro-container {
		padding-bottom: 4vw;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.container.home-cb-container.home-intro-container.reversed {
		flex-direction: column-reverse;
	}

	.home-cb-text-wrap.intro-cbox-text-wrap.reversed {
		margin-left: 0;
	}

	.container.home-cb-container.lower-cta-container {
		padding-right: 10vw;
		padding-left: 10vw;
	}

	.container.home-cb-container.featured-container {
		padding-top: 2vw;
		padding-bottom: 1vw;
	}

	.container.footer-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.container.footer-container.signoff-container {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	.container.inside-container {
		padding: 25px 30px;
	}

	.nav-section {
		display: block;
		height: auto;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		height: auto;
		max-width: 100%;
		min-height: 0px;
		margin-top: 0px;
		margin-right: auto;
		margin-left: auto;
		padding: 0rem 3vw;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1.25em 1.375rem;
		float: left;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
		font-size: 1.125rem;
		font-weight: 300;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #c9c6c4;
	}

	.nav-link.w--current {
		background-color: rgba(0, 0, 0, 0.3);
		background-image: none;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #fff;
		color: #fff;
		text-align: left;
	}

	.nav-link.w--current:hover {
		background-image: none;
		color: hsla(0, 0%, 100%, 0.75);
	}

	.nav-link.two-part-mobile {
		width: 79.75%;
		float: left;
		background-image: none;
	}

	.nav-link.two-part-mobile:hover {
		color: hsla(0, 0%, 100%, 0.75);
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: #fff;
		cursor: pointer;
	}

	.nav-link.mobile-list-back:hover {
		color: #fff;
	}

	.nav-menu-wrap {
		display: block;
		max-width: 100%;
		margin-top: 0px;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		border-left-style: none;
		background-color: #213a7f;
		text-align: left;
	}

	.logo-link {
		top: 0px;
		max-width: 250px;
		margin-right: 1rem;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 1.375rem;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-right-col {
		margin-top: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.column.footer-signoff-left-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.logo-image {
		max-width: 120px;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		padding: 0px 0.75rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		color: #6b6b6b;
		line-height: 3.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		color: #313131;
	}

	.menu-button.w--open {
		z-index: 1000;
		background-color: transparent;
		color: #fff;
	}

	.footer-signoff-section {
		text-align: center;
	}

	.copyright-text {
		margin-left: 0rem;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-signoff-grip {
		margin-left: 0em;
		float: none;
	}

	.footer-column-wrap {
		padding-bottom: 0rem;
	}

	.header-content-right-wrap {
		position: relative;
		z-index: 1200;
		display: none;
		height: 30px;
		margin-top: 0px;
		padding-top: 0px;
		padding-right: 3vw;
		padding-bottom: 0rem;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
		background-color: transparent;
	}

	.header-contents-wrap {
		display: block;
		height: auto;
		border-bottom: 1px solid #c9c6c4;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: left;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.form-field.header-search-field {
		z-index: 0;
		width: 0px;
		height: 1.9rem;
		margin-right: -39px;
		padding-left: 3vw;
		opacity: 0;
		font-size: 1rem;
	}

	.secondary-nav-list {
		display: none;
		height: 100%;
		margin-top: 0rem;
		margin-right: 0px;
		margin-bottom: 0em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.slideshow-container {
		max-width: 991px;
		margin-top: 123px;
	}

	.slider-mask {
		padding-bottom: 200px;
	}

	.slider-arrow {
		bottom: 8.5rem;
		width: 3rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 3.5rem;
	}

	.slider-indicator {
		bottom: 8.5rem;
		margin-right: 7rem;
	}

	.home-image-link {
		max-height: 180px;
		margin-right: 0%;
	}

	.home-image-link.large-image-link {
		margin-right: 0%;
		max-height: none;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5em;
		padding-bottom: 1.5em;
	}

	.home-content-box-wrap.large-content-box-wrap {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		padding-bottom: 0em;
	}

	.home-content-box-title {
		font-size: 1.4125em;
	}

	.home-content-box-title.intro-cb-title {
		font-size: 2.15rem;
		margin-bottom: 0.5rem;
	}

	.slider-caption-wrap {
		left: 0px;
		padding-right: 30px;
		padding-left: 30px;
		font-size: 1rem;
	}

	.inside-page-header-section {
		margin-top: 123px;
	}

	.main-content-wrapper {
		width: 100%;
		padding-top: 0;
	}

	.menu-button-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		color: #213a7f;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		display: none;
		height: 100%;
		margin-left: 0.125rem;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #c9c6c4;
		font-size: 0.75rem;
		line-height: 3.5rem;
		letter-spacing: -0.02em;
	}

	.home-cb-text-wrap.intro-cbox-text-wrap {
		width: 100%;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.home-cb-text-wrap.lower-cta-wrap {
		padding: 1.25rem 2.5rem 2.5rem 3rem;
	}

	.slider-caption-title {
		font-size: 1.4rem;
		line-height: 1.9rem;
	}

	.left-nav-wrapper {
		overflow: hidden;
		height: 0px;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		float: right;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background-color: rgba(0, 0, 0, 0.15);
		background-image: url("/20200408172800/local/public/shared/assets/images/websites/icon_arrow2_right_wht_50.svg");
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), -1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: transparent;
		font-size: 0.875rem;
		line-height: 2rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		background-color: #213a7f;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-top: -1px;
		margin-right: 0.25em;
		float: left;
		background-image: url("/20200408172800/local/public/shared/assets/images/websites/icon_arrow2_left_wht_50.svg");
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.header-content-right-row {
		width: auto;
		height: auto;
		margin-top: 0rem;
		margin-bottom: 0rem;
		background-color: transparent;
	}

	.header-search-wrap {
		margin-right: 0px;
		padding: 0rem 0vw;
		float: none;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-top-width: 1px;
		border-top-color: rgba(0, 0, 0, 0.05);
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
	}

	.header-search-form {
		width: auto;
	}

	.header-mobile-search {
		display: none;
		overflow: hidden;
		height: auto;
	}

	.mobile-menu-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.nav-search-button-icon {
		display: none;
	}

	.footer-social-icons-links-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.footer-signoff-back-top-button {
		margin-left: 0rem;
		font-size: 0.75rem;
		margin-right: 3rem;
	}

	.last-content-box {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-image-link-text {
		padding-left: 5%;
		font-size: 1.25rem;
		line-height: 1.5rem;
	}

	.cta-paragraph p {
		max-width: none;
	}

	.inside-content-wrapper {
		padding-bottom: 1rem;
	}

	.left-nav-col {
		display: none;
	}

	.content-wrapper {
		position: relative;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.ecomm-header-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 14px;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 0.6875rem;
		font-weight: 500;
	}

	.ecomm-header-link.cart-icon {
		height: 2.5rem;
		margin-right: 0.5rem;
	}

	.ecomm-header-link.account-icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 1.25rem;
	}

	.ecomm-header-wrap {
		margin-left: 30px;
	}

	.home-content-box-title-2.intro-cbox-title {
		font-size: 2.25em;
	}

	.home-cbox-text-wrap {
		font-size: 0.9375rem;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: 90%;
		font-size: 1.25rem;
	}

	.container-2 {
		padding-right: 20px;
		padding-left: 20px;
	}

	.home-content-box-wrap-2 {
		margin-bottom: 1.5rem;
	}

	.home-content-box-wrap-2.intro-content-box-wrap {
		margin-bottom: 0rem;
		padding-bottom: 0rem;
	}

	.intro-cbox-section {
		margin-top: 1em;
		padding-top: 2em;
		padding-bottom: 2em;
	}

	.language {
		margin-right: 10px;
		margin-left: 0px;
	}

	.contact-link {
		display: none;
		padding-left: 1rem;
		text-align: left;
	}

	.header-social {
		display: block;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.header-social-icon {
		display: block;
		width: auto;
		height: 25px;
		max-width: none;
	}

	.header-social-link {
		width: 25px;
		height: 25px;
		background-color: rgba(0, 0, 0, 0.15);
		opacity: 0.7;
	}

	.header-social-link:hover {
		opacity: 1;
	}

	.div-block-2 {
		width: 100%;
		margin-top: 10px;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.cb-static._50percent-left {
		width: 100%;
		padding-right: 0rem;
	}

	.cb-static._50percent-right {
		width: 100%;
		padding-left: 0rem;
	}

	.home-content-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 4rem;
		border-top-color: #e2e2e2;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-3 {
		max-width: 991px;
	}

	.container-3.home-content-container {
		padding-right: 30px;
		padding-left: 30px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.insta-inner-wrap {
		width: 384px;
		height: 384px;
	}

	.cb-feature-heading {
		font-size: 2.25rem;
		line-height: 2.7rem;
	}

	.container-4 {
		max-width: 768px;
	}

	.feature-cb-paragraph p {
		font-size: 1.15rem;
		line-height: 2rem;
	}

	.insta-tile-link {
		margin-bottom: 30px;
	}

	.insta-tile-link.insta-3 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-4 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-2 {
		margin-bottom: 0px;
	}

	.insta-tile-link.insta-1 {
		margin-bottom: 0px;
	}

	.insta-small-image-wrap {
		width: 192px;
		height: 192px;
	}

	.cb-heading-2.clients-cb-head {
		font-size: 2.014rem;
		line-height: 2.714rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding: 0 30px;
	}

	.clients-slider-slide {
		width: 25%;
	}

	.clients-slider {
		margin-right: 0.75rem;
		margin-left: 0.75rem;
	}

	.share-button {
		width: 40px;
		height: 40px;
	}

	.nav-drop-toggle-link-end {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.gear {
		width: 40px;
		height: 40px;
	}

	.div-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.div-block-4 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.navigation-link {
		width: 100%;
		border-bottom: 1px solid #fff;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
		color: #fff;
		text-align: left;
	}

	.navigation-link:hover {
		background-color: #012b5d;
		box-shadow: none;
	}

	.navigation-link.w--current {
		border-right-color: transparent;
		border-bottom-style: solid;
		border-bottom-color: #fff;
		background-color: #012b5d;
		box-shadow: inset 0 0 8px 0 #012b5d;
		color: #fff;
	}

	.header-search-container {
		top: 124px;
		height: 0px;
	}

	.mobile-search-link {
		position: static;
		top: 0px;
		right: 4.25rem;
		width: 1.3rem;
		height: 1.2rem;
		margin-right: 10px;
		padding-right: 0px;
		padding-bottom: 0px;
		float: right;
		background-image: url("/20200408172800/local/public/shared/assets/images/websites/search_navy.svg");
		background-position: 50% 50%;
		background-size: contain;
		background-repeat: no-repeat;
		-webkit-transition: all 200ms ease;
		transition: all 200ms ease;
		cursor: pointer;
	}

	.mobile-social {
		padding-right: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.link-block {
		width: 25px;
		height: 25px;
	}

	.div-block-6 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 50px;
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.image-2 {
		width: 25px;
		height: 25px;
	}

	.link-block-2 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30px;
		height: 30px;
		margin-right: 0px;
		padding: 4px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 100%;
		background-color: #fff;
		opacity: 1;
	}

	.image-3 {
		width: auto;
	}

	.link-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 25px;
		height: auto;
		margin-right: 24px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-7 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.div-block-7 > a {
		margin-right: 10px;
	}

	.div-block-7 > a:last-of-type {
		margin-right: 0;
	}

	.link-block-2-copy {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 30px;
		height: 30px;
		padding: 4px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		border-radius: 100%;
		background-color: #fff;
		opacity: 1;
	}

	.copyright-text {
		text-align: left;
	}

	.footer-signoff-list {
		text-align: right;
	}

	.footer-column-wrap p {
		font-size: 0.9rem;
	}

	.footer-column-wrap ul {
		font-size: 0.9rem;
	}

	.cta-paragraph p {
		font-size: 1.2rem;
		line-height: 1.45;
	}

	.slide-image {
		max-height: 500px;
		height: 40vw;
	}
}

@media screen and (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
		margin-left: 0 !important;
		margin-right: 0 !important;
	}

	body {
		font-size: 1rem;
	}

	h1 {
		font-size: 2rem;
		line-height: 2.5rem;
	}

	h2 {
		font-size: 1.6rem;
		line-height: 2.1rem;
	}

	.button.header-search-button {
		height: 1.9rem;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.home-cb-container {
		padding-right: 30px;
		padding-bottom: 3vw;
		padding-left: 30px;
	}

	.container.home-cb-container.home-intro-container {
		padding-top: 0vw;
	}

	.container.home-cb-container.featured-container {
		padding-bottom: 0vw;
	}

	.nav-container {
		padding-bottom: 0rem;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.two-part-mobile {
		width: 79.75%;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15);
	}

	.nav-menu-wrap {
		background-color: #213a7f;
		background-image: none;
	}

	.logo-link {
		top: 0px;
		max-width: 230px;
	}

	.row.footer-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.home-cbox-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.logo-image {
		max-width: 100px;
	}

	.menu-button {
		padding-right: 0.6rem;
		padding-left: 0.6rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
	}

	.footer-column-wrap {
		min-width: 35%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
		text-align: center;
	}

	.header-content-right-wrap {
		margin-top: 0px;
	}

	.breadcrumb-list {
		font-size: 0.6875rem;
	}

	.form-field.header-search-field {
		height: 1.9rem;
	}

	.secondary-nav-list {
		margin-right: 0px;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.slideshow-container {
		position: static;
		margin-top: 106px;
	}

	.slider-mask {
		padding-bottom: 236px;
	}

	.slider-arrow {
		left: 30px;
		right: auto;
		bottom: auto;
		width: 2rem;
		height: 2rem;
		margin-top: -3rem;
		margin-left: 2.5rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.slider-indicator {
		left: 110px;
		top: auto;
		right: auto;
		bottom: auto;
		margin-top: -3.25rem;
		margin-right: 0rem;
		padding-right: 20px;
		background-image: none;
	}

	.slider-indicator:hover {
		background-color: transparent;
	}

	.home-image-link {
		max-height: 140px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		height: 55vw;
		font-size: 1.5rem;
	}

	.home-content-box-wrap.large-content-box-wrap {
		border-bottom: 1px none rgba(0, 0, 0, 0.1);
	}

	.home-content-box-title.intro-cb-title {
		font-size: 1.8rem;
		margin-top: 1em;
	}

	.slider-caption-wrap {
		max-width: none;
		padding-right: 1.25rem;
		font-size: 1rem;
	}

	.main-content-wrapper {
		overflow: visible;
		padding-top: 0vw;
	}

	.main-content-wrapper.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.two-part-button-toggle {
		background-color: rgba(0, 0, 0, 0.25);
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.15), -1px 0 0 0 hsla(0, 0%, 100%, 0.15);
		line-height: 1.6875rem;
	}

	.header-content-right-row {
		height: auto;
	}

	.footer-signoff-back-top-button {
		padding-top: 1em;
		padding-bottom: 1em;
		font-size: 0.625rem;
		line-height: 1.5em;
	}

	.last-content-box {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-image-link-text {
		padding-left: 1rem;
		font-size: 1.35rem;
	}

	.footer-paragraph {
		text-align: center;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.5em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.home-content-box-title-2 {
		line-height: 1.125em;
	}

	.home-cbox-learn-more {
		margin-bottom: 1rem;
	}

	.home-cbox-text-wrap {
		padding-bottom: 2.75rem;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		max-width: none;
		font-size: 1.125rem;
		text-align: left;
	}

	.container-2 {
		padding-right: 10px;
		padding-left: 10px;
	}

	.link.content-box-title-link:hover {
		border-bottom-color: transparent;
	}

	.home-content-box-wrap-2 {
		border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	}

	.home-content-box-wrap-2.intro-content-box-wrap {
		width: auto;
		margin-right: 10px;
		margin-left: 10px;
		float: none;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		border-bottom-style: none;
	}

	.intro-cbox-section {
		margin-top: 0em;
	}

	.paragraph-extra-lineheight.sponsor-paragraph {
		margin-right: 0rem;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.cb-static {
		width: 33.33%;
	}

	.home-content-section.two-cb-home-content-section {
		padding-bottom: 2rem;
		border-top-color: #e2e2e2;
	}

	.container-3 {
		max-width: 767px;
	}

	.container-3.home-content-container {
		padding-right: 2rem;
		padding-left: 2rem;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.insta-inner-wrap {
		width: 416px;
		height: 416px;
	}

	.cb-feature-heading {
		font-size: 2.25rem;
		line-height: 2.6rem;
	}

	.container-4 {
		max-width: 550px;
	}

	.home-content-section-2 {
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
	}

	.home-content-section-2.hcs-row-1 {
		padding-top: 2.5rem;
		padding-bottom: 4rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.cb-header-wrap {
		padding-right: 3.25rem;
		padding-left: 3.25rem;
	}

	.insta-tile-link {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.insta-small-image-wrap {
		width: 208px;
		height: 208px;
	}

	.instagram-wrapper {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.cb-heading-2.clients-cb-head {
		line-height: 2.714rem;
	}

	.clients-slider-slide {
		width: 33.33%;
	}

	.div-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.div-block-4 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}

	.header-search-container {
		top: 107px;
	}

	.div-block-6 {
		height: 45px;
	}

	.link-block-2 {
		width: 25px;
		height: 25px;
	}

	.link-block-2-copy {
		width: 25px;
		height: 25px;
	}

	.cta-paragraph p {
		font-size: 1.15rem;
		line-height: 1.4;
	}

	.slide-image {
		max-height: 450px;
	}

	.inside-page-header-section {
		margin-top: 106px;
	}
}

@media screen and (max-width: 650px) {
	.column.footer-signoff-right-col {
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 100%;
	}

	.column.footer-signoff-left-column {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		width: 100%;
	}

	.container.footer-container.signoff-container {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-signoff-list,
	.copyright-text {
		text-align: center;
		width: 100%;
	}

	.footer-signoff-list {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.footer-signoff-grip {
		margin: 1rem;
	}

	.footer-signoff-back-top-button {
		margin: 1em 1rem;
	}
}

@media screen and (max-width: 479px) {
	.div-block-7 > a {
		margin-right: 5px;
	}

	h1 {
		font-size: 1.9rem;
		line-height: 2.4rem;
	}

	h2 {
		font-size: 1.5rem;
		line-height: 2rem;
	}

	.button.header-search-button {
		height: 1.9rem;
		padding-right: 1em;
		padding-left: 1em;
	}

	.container.home-cb-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.home-intro-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.lower-cta-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.home-cb-container.featured-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.nav-container {
		padding-top: 0rem;
		padding-bottom: 0rem;
		padding-left: 1rem;
	}

	.nav-link {
		font-size: 0.9375rem;
	}

	.nav-link.two-part-mobile {
		width: 79%;
	}

	.logo-link {
		max-width: 180px;
		margin-right: 0px;
		padding-left: 0px;
	}

	.footer-section {
		text-align: center;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		-ms-grid-row-align: stretch;
		align-self: stretch;
	}

	.footer-column-wrap {
		width: 100%;
		min-width: 50%;
		padding-right: 0em;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.header-content-right-wrap {
		height: 30px;
		margin-top: 0px;
		margin-right: 0em;
		padding-top: 0px;
		padding-bottom: 0rem;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 0;
		-webkit-flex: 0 0 auto;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
	}

	.form-field.header-search-field {
		width: 26px;
		height: 1.9rem;
		margin-right: -30px;
		font-size: 0.875rem;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.slider-mask {
		padding-bottom: 280px;
	}

	.slider-arrow {
		left: 20px;
	}

	.slider-indicator {
		left: 100px;
	}

	.home-image-link {
		height: 25vw;
		min-height: 100px;
		min-width: 35%;
		margin-right: 5%;
		float: none;
	}

	.home-image-link.large-image-link {
		height: 54vw;
		margin-right: 0%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-content-box-wrap.large-content-box-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		flex-direction: row;
	}

	.home-content-box-title.intro-cb-title {
		font-size: 1.5rem;
	}

	.slider-caption-wrap {
		padding-left: 1.25rem;
	}

	.menu-button-icon {
		height: auto;
		color: #213a7f;
	}

	.menu-button-text {
		display: none;
	}

	.home-cb-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cb-text-wrap.intro-cbox-text-wrap {
		padding-right: 0em;
		padding-left: 0em;
	}

	.home-cb-text-wrap.lower-cta-wrap {
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.slider-caption-title {
		max-width: 100%;
		text-align: left;
	}

	.footer-social-icons-section-wrap {
		width: 100%;
		padding-right: 0px;
		float: none;
	}

	.two-part-button-toggle {
		font-size: 0.8125rem;
	}

	.header-content-right-row {
		width: auto;
		height: auto;
		-webkit-box-flex: 0;
		-webkit-flex: 0 auto;
		-ms-flex: 0 auto;
		flex: 0 auto;
	}

	.header-search-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: auto;
		padding-right: 0rem;
		padding-left: 0vw;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: end;
		-webkit-align-items: flex-end;
		-ms-flex-align: end;
		align-items: flex-end;
	}

	.header-search-form {
		width: auto;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.footer-social-icons-links-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.last-content-box {
		margin-right: 0px;
		margin-left: 0px;
	}

	.header-section {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: reverse;
		-webkit-flex-direction: column-reverse;
		-ms-flex-direction: column-reverse;
		flex-direction: column-reverse;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.ecomm-header-link {
		padding-right: 0.5em;
		padding-left: 0.25em;
	}

	.ecomm-header-link.cart-icon {
		padding-right: 0em;
	}

	.ecomm-header-link.account-icon {
		margin-right: 0.5rem;
	}

	.ecomm-header-wrap {
		margin-left: 1rem;
	}

	.home-content-box-title-2.intro-cbox-title {
		font-size: 1.5625em;
	}

	.home-cbox-text-wrap {
		width: 100%;
		float: none;
	}

	.language {
		margin-top: 0px;
	}

	.contact-link {
		margin-top: 0px;
		margin-right: 10px;
		margin-bottom: 10px;
		padding-right: 0.5rem;
		padding-left: 0.5rem;
		font-size: 0.9rem;
	}

	.header-social {
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.header-social-icon {
		height: 20px;
		padding-top: 4px;
	}

	.header-social-link {
		width: 20px;
		height: 20px;
		background-color: rgba(0, 0, 0, 0.15);
		opacity: 0.7;
	}

	#news_module .cms_list_item .cms_description p,
	.paragraph-extra-lineheight {
		font-size: 1rem;
		line-height: 1.4;
	}

	.spotlight-content-wrapper {
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.cb-static._50percent-left {
		padding-top: 0rem;
	}

	.cb-static._50percent-right {
		padding-bottom: 0rem;
	}

	.home-content-section {
		padding-top: 1rem;
	}

	.home-content-section.sponsors-section {
		padding-bottom: 3rem;
	}

	.container-3 {
		max-width: none;
	}

	.insta-inner-wrap {
		width: 340px;
		height: 340px;
	}

	.cb-feature-heading {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.container-4 {
		max-width: none;
	}

	.home-content-section-2 {
		padding-bottom: 4.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	}

	.cb-header-wrap {
		padding-right: 0.25rem;
		padding-left: 0.25rem;
	}

	.insta-tile-link {
		width: 100%;
	}

	.insta-small-image-wrap {
		width: 170px;
		height: 170px;
	}

	.instagram-wrapper {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.clients-slider-slide {
		width: 100%;
		height: 125px;
		margin-top: 0px;
	}

	.clients-slider-slide.slide-1 {
		height: 120px;
	}

	.clients-slider {
		height: 125px;
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.clients-slider-image {
		max-height: 75px;
		max-width: 150px;
	}

	.div-block-3 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-top: 0px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-4 {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
	}

	.header-search-container {
		width: 100%;
	}

	.search-form-wrap {
		width: 100%;
		background-color: hsla(0, 0%, 100%, 0.9);
	}

	.search-link {
		display: block;
		font-size: 0.875rem;
	}

	.mobile-search-link {
		left: 0rem;
		top: 4.25rem;
		right: auto;
		z-index: 6;
		width: 1.3rem;
		height: 1.2rem;
		margin-right: 0px;
		padding-right: 0px;
		padding-bottom: 0px;
	}

	.mobile-social {
		margin-top: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-terms {
		margin-bottom: 1rem;
	}

	.container-3.home-content-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.cb-head-wrapper.sponsorships-header-wrapper {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.container.inside-container {
		padding-right: 1.25rem;
		padding-left: 1.25rem;
	}

	.about-our-company-image {
		background-size: 100%;
	}

	.slide-image {
		max-height: 300px;
		height: 40vw;
	}
}

@media (max-width: 320px) {
	.home-image-link.large-image-link {
		height: 50vw;
	}
}

/* IE11 fixes */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.header-section {
		position: fixed;
		width: 100%;
	}
}
